import "./Header.css"

function Header() {
    return (
        <div className="Header" style={{ width: "100%", minHeight: "100px", paddingTop: "10px" }}>
            <div className="headerContainer">
                <div className="logoContainer" ><a href="/" style={{ textDecoration: "none", color: "#d5af80" }}>Vrindavan Vatika</a></div>
                <div>
                    <div className="rightMenuContainer">
                        <div className="contactContainer">
                            <div className="phoneSymbolContainer">&#9990;</div>
                            <div className="contactContainer">+91 9569151820</div>
                        </div>
                        <div className="menuContainer">
                            <div className="menuParent">&#9776;</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Header;
