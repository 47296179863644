import './App.css';
import Header from './Header'
import Home from './Home'
import Footer from './Footer'

function App() {
  return (
      <div className="App">
          {/*<div style={{ backgroundImage: "url(" + process.env.PUBLIC_URL + "/background.jpg)", backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize:"cover", height:"700px"}}>*/}
          <div>
            <Header />
              <Home />
              <Footer />
          </div>
    </div>
  );
}

export default App;
