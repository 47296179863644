import "./Home.css"

function Home() {
    return (
        <div className="Home">
            <div className="mainBanner">
                <div className="mainTopBanner">
                    <div className="topBannerLeft"></div>
                    <div className="topBannerCenter"  ><img className="homeImageMain" src={process.env.PUBLIC_URL + "/feather.jpg"} /></div>
                    <div className="topBannerRight"></div>
                </div>
                <div className="mainCenterBanner">
                    <div className="centerBannerTop">Welcome to</div>
                    <div className="centerBannerMid">VRINDAVAN VATIKA</div>
                    <div className="centerBannerBottom">BANQUET HALL</div>
                </div>
                <div className="mainBottomBanner">
                    <div className="lowerBannerTop">Since 2025</div>
                    <div className="lowerBannerBottom">******</div>
                </div>
            </div>


            <section className="textSection" >
                <div>
                    <div>
                        <span className="textHeader">WELCOME TO VRINDAVAN VATIKA</span>
                    </div>
                    <div className="textPara">
                        <p>
                            Vrindavan Vatika is one of the most contemporary and technologically 
                            advanced banquet halls in Sonbhadra, Uttar pradesh. 
                            We offer specialized, all inclusive amenities and services which 
                            set us apart from the competition. we have considered every detail and perfected your 
                            experience at Vrindavan Vatika. 
                            We take pride in the versatility and quality of our venue and services. 
                            Our management and staff work tirelessly to ensure that all events 
                            go above and beyond your expectations. Whether it's a corporate event 
                            or your wedding day, let's make it a one of a kind experience.
                        </p>
                    </div>




                </div>
            </section>
        </div>
    );
}

export default Home;
