import "./Footer.css"

function Footer() {
    return (
        <div className="footer">
            <div className="footerContainer">
                <div className="footerLogoTextMain">VRINDAVAN VATIKA</div>
                <div className="footerLogoTextSub">BANQUET HALL</div>
            </div>
            <div className="footerInfoMain">
                <div className="footerInfo">
                    <div className="footerInfoDetails">
                        <div><a href="">Home</a></div>
                        <div><a href="">Venue</a></div>
                        <div><a href="">Events</a></div>
                        <div><a href="">Upcoming Events</a></div>
                        <div><a href="">Gallery</a></div >
                        <div><a href="">About Us</a></div >
                        <div><a href="">Contact Us</a></div >
                    </div>
                    <div className="footerInfoDetails">
                        <div><a href="">Wedding</a></div >
                        <div><a href="">Birthday</a></div >
                        <div><a href="">Events by Vrindavan Vatika</a></div >
                        <div><a href="">Corporate Events</a></div >
                    </div>

                </div>
                <div className="contactInfo">
                    <div className="contactInfoSub">
                        <div className="contactHeader">For Bookings</div>
                        <div className="contactCard">
                            <div className="contactName">Sanjay Singh</div>
                            <div className="contactNumber">+91 63869 06853</div>
                        </div>
                        <div className="contactCard">
                            <div className="contactName">Roshan Singh Patel</div>
                            <div className="contactNumber">+91 93692 41293</div>
                        </div>
                        <div className="contactHeader">For Events</div>
                        <div className="contactCard">
                            <div className="contactName">Shashi Prabha Singh</div>
                            <div className="contactNumber">+91 93358 61340</div>
                        </div>
                    </div>
                    <div className="contactInfoSub">
                        <div className="contactHeader">Location</div>
                        <div className="contactCard">
                            <div className="contactName">Vrindavan Vatika - Saranga</div>
                            <div className="contactNumber"><iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d2072.768552601073!2d82.88084637363455!3d24.80921938484614!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e1!3m2!1sen!2sin!4v1733233616206!5m2!1sen!2sin" width="200" height="170" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="copyRight">
                COPYRIGHT &#169; 2024 VRINDAVAN VATIKA BANQUET HALL.
            </div>
        </div>
    );
}

export default Footer;